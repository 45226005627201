<template>
  <div class="body add-business">
    <el-form
      :model="addForm"
      :rules="rule"
      ref="addForm"
      :inline="true"
      label-width="82px"
      label-position="left"
      :disabled="disabled"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="上级节点：" prop="parentName">
        <el-input v-model.trim="addForm.parentName" disabled></el-input>
      </el-form-item>
      <el-form-item label="节点名称：" prop="name">
        <el-input
          v-model.trim="addForm.name"
          placeholder="请输入节点名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="节点类型：" prop="type">
        <el-select
          v-model="addForm.type"
          placeholder="请选择"
          :disabled="!typeVisible"
        >
          <el-option label="公司" :value="1"></el-option>
          <el-option label="车队" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属系统" prop="system" v-if="addForm.type == 2">
        <el-select
          v-model="addForm.system"
          clearable
          placeholder="请选择所属系统"
        >
          <el-option
            v-for="item in systemList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构代码：" prop="orgCa" v-if="addForm.type < 2">
        <el-input v-model.trim="addForm.orgCa" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item
        label="法人代表："
        prop="corporation"
        v-if="addForm.type < 2"
      >
        <el-input
          v-model.trim="addForm.corporation"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="地址：" prop="address" v-if="addForm.type < 2">
        <el-input
          v-model.trim="addForm.address"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="经度：" prop="longitude" v-if="addForm.type < 2">
        <el-input
          v-model.trim="addForm.longitude"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="纬度：" prop="latitude" v-if="addForm.type < 2">
        <el-input
          v-model.trim="addForm.latitude"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="服务单位："
        prop="serviceOrgan"
        v-if="addForm.type < 2"
      >
        <el-input
          v-model.trim="addForm.serviceOrgan"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="投诉电话："
        prop="complaintTel"
        v-if="addForm.type < 2"
      >
        <el-input
          v-model.trim="addForm.complaintTel"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="公司电话："
        prop="companyPhone"
        v-if="addForm.type < 2"
      >
        <el-input
          v-model.trim="addForm.companyPhone"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人：" prop="linkMan">
        <el-input
          v-model.trim="addForm.linkMan"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" prop="phone">
        <el-input v-model.trim="addForm.phone" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  checkName,
  checkNodeName,
  checkPhone,
  checkLandLine,
  checkLong,
  checkLat
} from '@/common/utils/index.js'
import {
  insertCompany,
  updateCompany,
  queryDictsByCodes,
  getCompanyDetailLabels
} from '@/api/lib/api.js'

export default {
  props: {
    addForm: {
      type: Object,
      default: function (params) {
        return {}
      }
    },
    isEdit: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      typeVisible: true,
      systemList: [],
      rule: {
        name: [
          { required: true, message: '请输入节点名称', trigger: 'blur' },
          { validator: checkNodeName, trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择节点类型', trigger: 'change' }
        ],
        system: [
          { required: true, message: '请选择所属系统', trigger: 'change' }
        ],
        linkMan: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          { validator: checkName, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        complaintTel: [{ validator: checkLandLine, trigger: 'blur' }],
        longitude: [{ validator: checkLong, trigger: 'blur' }],
        latitude: [{ validator: checkLat, trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 新增/修改菜单
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const data = this.addForm
          if (this.isEdit) {
            insertCompany(data).then((res) => {
              if (res.code === 1000) {
                this.$message({
                  type: 'success',
                  showClose: true,
                  message: '新增成功'
                })
                this.closeDia(res.code === 1000)
              } else {
                this.$message({
                  type: 'error',
                  showClose: true,
                  message: res.msg
                })
                this.$emit('onLoading', false)
              }
            })
          } else {
            updateCompany(data).then((res) => {
              if (res.code === 1000) {
                this.$message({
                  type: 'success',
                  showClose: true,
                  message: '修改成功'
                })
                this.closeDia(res.code === 1000)
              } else {
                this.$message({
                  type: 'error',
                  showClose: true,
                  message: res.msg
                })
                this.$emit('onLoading', false)
              }
            })
          }
        }
      })
    },
    closeDia(flag) {
      this.$emit('close', flag)
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'XTLB' }).then((res) => {
        if (res.code === 1000) {
          this.systemList = res.data.XTLB
        }
      })
    }
  },
  mounted() {
    this.getDicts()
  }
}
</script>

<style lang="scss" scoped>
.add-business {
  @include themify() {
    .modify {
      color: themed('n6');
      margin-left: 8px;
      &.active {
        color: themed('b4');
      }
    }
  }
}
</style>
