<template>
  <div class="business-management">
    <div class="left">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        ref="companyTree"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        :highlight-current="true"
        :default-expanded-keys="[0]"
        @node-click="nodeClick"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span class="treeName">{{ node.label }}</span>
          <span>
            <el-button
              v-if="data.type !== 2"
              class="icon-menu-btn"
              type="text"
              size="mini"
              @click.stop="() => append(node, data, 0)"
            >
              <i class="icon-menu el-icon-circle-plus-outline"></i>
            </el-button>
            <el-button
              v-if="data.type !== 0"
              class="icon-menu-btn"
              type="text"
              size="mini"
              @click.stop="() => edit(node, data, 1)"
            >
              <i class="icon-menu el-icon-edit-outline"></i>
            </el-button>
            <el-button
              v-if="data.type !== 0"
              class="icon-menu-btn"
              type="text"
              size="mini"
              @click.stop="() => remove(node, data)"
            >
              <i class="icon-menu el-icon-remove-outline"></i>
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="right">
      <add-business v-if="dialogVisible" :addForm="addForm" :disabled="true" />
    </div>
    <!--添加/编辑节点弹框-------------------start-->
    <el-dialog
      :title="title"
      :visible.sync="treeDialogVisible"
      v-if="treeDialogVisible"
      class="tree-operate-dialog"
      @close="treeDialogVisible = false"
    >
      <add-business
        ref="addCompany"
        :isEdit="title === '新增企业'"
        :addForm="addForm"
        @close="onDialogClose"
        @onLoading="onLoading"
      />
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="treeDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="saveTree"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--添加/编辑节点弹框-------------------end-->
  </div>
</template>

<script>
import addBusiness from './components/addBusiness'
import {
  getAuthCompanyTree,
  deleteCompany,
  getCompanyDetailLabels
} from '@/api/lib/api.js'

export default {
  name: 'menuManagement',
  components: { addBusiness },
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'subCompanyTrees',
        label: 'name'
      },
      addForm: {},
      title: '',
      treeDialogVisible: false,
      dialogVisible: false,
      treeFlag: false,
      editLoading: false,
      title: ''
    }
  },
  methods: {
    // 添加节点操作
    append(node, data, flag) {
      this.treeFlag = flag
      this.title = '新增企业'
      this.addForm = { parentId: data.id, parentName: data.name }
      this.treeDialogVisible = true
    },
    // 编辑节点操作
    edit(node, data, flag) {
      this.treeFlag = flag
      this.title = '编辑企业'
      this.getDetail(data.id)
      this.treeDialogVisible = true
    },
    // 删除节点操作
    remove(node, data) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [h('span', null, '确认删除该节点？')]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteCompany(data.id).then((res) => {
            if (res.code === 1000) {
              this.$message.success('操作成功')
              this.getCompanyList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },
    // 公司详情
    getDetail(id) {
      getCompanyDetailLabels({ id: id }).then((res) => {
        if (res.code === 1000) {
          this.addForm = res.data
          if (this.addForm.system != null) {
            this.addForm.system = this.addForm.system + ''
          }
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
    },
    // 关闭弹窗
    onDialogClose(flag) {
      this.treeDialogVisible = false
      this.editLoading = false
      if (flag) {
        this.getCompanyList()
      }
    },
    onLoading(flag) {
      this.editLoading = flag
    },
    // 点击节点
    nodeClick(data) {
      this.getDetail(data.id)
      this.dialogVisible = true
    },
    // 保存添加和编辑
    saveTree() {
      this.$refs.addCompany.onSave()
    },
    // 获取企业树
    getCompanyList() {
      getAuthCompanyTree({ isPermission: 1 }).then((res) => {
        if (res.code === 1000) {
          this.treeData = [res.data]
        }
      })
    }
  },
  created() {
    this.getCompanyList()
  }
}
</script>

<style lang="scss" scoped>
.business-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  @include themify() {
    .left {
      width: 35%;
      background-color: themed('n1');
      padding: 20px;
      overflow: auto;
      .treeName {
        margin-right: 15px;
      }
      .icon-menu-btn {
        margin-left: 0;
        .icon-menu {
          padding: 5px;
        }
      }
    }
    .right {
      width: 65%;
    }
  }
}

.theme-project-resourse {
  .business-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
